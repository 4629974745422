import React from 'react';
import styled from 'styled-components';

import {
  headingXl, desktopVW, minTablet, mobileVW,
} from '../../styles';

interface componentProps {
  hero: {
    layout: string
    heading: string
  }
}

export const HeadingHero = ({ hero }: componentProps) => (
  <Header>
    <H1>{hero.heading}</H1>
  </Header>
);

const Header = styled.header`
  margin: ${mobileVW(15)};

  ${minTablet} {
    margin: ${desktopVW(60)};
  }
`;

const H1 = styled.h1`
  ${headingXl};
`;
