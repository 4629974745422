import React from 'react';
import { FullHero } from './FullHero';
import { HeadingHero } from './HeadingHero';
import { ProductHero } from './ProductHero';

interface componentProps {
  hero: {
    layout: string
    heading: string
    subHeading: string
    product: any,
    image: {
      altText: string
      title: string
      localFile : {
        childImageSharp : {
          fluid : {
            src: string
          }
        }
      }
    }
    video: {
      id: any
      sourceUrl: string
      mediaItemUrl: string
    }
    poster: {
      localFile : {
        childImageSharp : {
          fluid : {
            src: string
          }
        }
      }
    }
    button: {
      title: string
      target: string
      url: string
    }
  }
}

export const Hero = ({ hero }: componentProps) => {
  if (hero.layout === 'is_heading') {
    return <HeadingHero hero={hero} />;
  }
  if (hero.layout === 'is_image') {
    return <FullHero hero={hero} />;
  }
  if (hero.layout === 'is_video') {
    return <FullHero hero={hero} />;
  }
  if (hero.layout === 'is_product') {
    // @ts-ignore slordig gedaan dittttt is niet de juiste manier
    return <ProductHero hero={hero} />;
  }
  return null;
};
