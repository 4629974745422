import React, { useState } from 'react';
import styled from 'styled-components';
import {
  color, desktopVW, minTablet, mobileVW,
} from '../styles';

export const Accordion = ({ items, className } : { items : { map?: any, title: string, body: string }, className?: any}) => (
  <StyledAccordion className={className}>

    {items.map(({ title, body }: any) => {
      const [active, setActive] = useState(false);

      return (
        <StyledItem key={title}>
          <StyledHeading onClick={() => setActive(!active)}>
            <StyledTitle>{title}</StyledTitle>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.714 8.714"><g fill="none" stroke="#080912"><path d="M8.714 4.357H0M4.357 8.714V0" /></g></svg>
          </StyledHeading>
          <StyledBody hide={active}>
            {body}
          </StyledBody>
        </StyledItem>
      );
    })}

  </StyledAccordion>
);

const StyledAccordion = styled.ul`
  border-top: ${mobileVW(1)} solid ${color.greyLight};
  
  ${minTablet} {
    border-top: ${desktopVW(1)} solid ${color.greyLight};
  }
`;

const StyledItem = styled.li`
  border-bottom: ${mobileVW(1)} solid ${color.greyLight};
  
  ${minTablet} {
    border-bottom: ${desktopVW(1)} solid ${color.greyLight};
  }
  `;

const StyledHeading = styled.div`
  margin-top: ${mobileVW(30)};
  padding-bottom: ${mobileVW(30)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
  ${minTablet} {
    margin-top: ${desktopVW(30)};
    padding-bottom: ${desktopVW(30)};  
  }

  svg {
    width: ${mobileVW(9)};

    ${minTablet} {
      width: ${desktopVW(9)};
    }
  }
`;

const StyledTitle = styled.h5``;

const StyledBody = styled.h5`
  display: ${({ hide } : { hide: boolean }) => (hide ? 'block' : 'none')};
  padding-bottom: ${mobileVW(30)};
  color: #5B5E69;
  
  ${minTablet} {
    padding-bottom: ${desktopVW(30)};
  }
`;
