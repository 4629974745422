import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { Button } from '../../shared/Button';
import { Video } from '../../shared/Video';

import {
  radius, color, desktopVW, minTablet, mobileVW,
} from '../../styles';
import { Typography } from '../../shared/Typography';

interface componentProps {
  hero: {
    layout: string
    heading: string
    subHeading: string
    image: {
      altText: string
      title: string
      localFile : {
        childImageSharp : {
          fluid : {
            src: string
          }
        }
      }
    }
    video: {
      mediaItemUrl: string
    }
    poster: {
      localFile: {
        childImageSharp: {
          fluid: {
            src: string
          }
        }
      }
    }
    button: {
      title: string
      target: string
      url: string
    }
  }
}

export const FullHero = ({ hero }: componentProps) => (
  <Header>
    <Container>
      <H1 size="is-xl" variants="h1">{ hero.heading }</H1>
      {hero.subHeading && (
        <H2 size="intro-is-rg" variants="h2">{ hero.subHeading }</H2>
      )}
      {hero.button && (
        <A type="link" url={hero.button.url}>{ hero.button.title }</A>
      )}
      {(() => {
        if (hero.layout === 'is_image') {
          return (
            <StyledImage
              //  @ts-ignore
              fluid={hero.image.localFile.childImageSharp.fluid}
              alt={hero.image.altText}
            />
          );
        }
        return (
          <StyledVideo poster={hero.poster.localFile.childImageSharp.fluid} mp4={hero.video.mediaItemUrl} />
        );
      })()}
    </Container>
  </Header>
);

const Header = styled.header`
  margin: 0 ${mobileVW(15)};
  margin-bottom: ${mobileVW(60)};
  text-align: center;
  ${radius};
  overflow: hidden;

  ${minTablet} {
    margin: 0 ${desktopVW(60)} ${desktopVW(120)};
  }
`;

const A = styled(Button)`
  margin-top: ${mobileVW(30)};
  position: relative;
  z-index: 1;

  ${minTablet} {
    margin-top: ${desktopVW(60)};
  }
`;

const StyledVideo = styled(Video)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${radius};
  overflow: hidden;
  opacity: 0.75;
`;

const Container = styled.div`
  position: relative;
  background-color: ${color.black};
  /* padding: ${mobileVW(90)} ${mobileVW(15)}; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${mobileVW(500)};
  
  ${minTablet} {
    /* padding: ${desktopVW(120)} 0; */
    min-height: ${desktopVW(600)};
    max-height: ${desktopVW(900)};
    height: calc(100vh - ${desktopVW(160)});
  }
`;

const H1 = styled(Typography)`
  color: ${color.white};
  z-index: 1;
  position: relative;
  margin-bottom: ${mobileVW(30)};

  ${minTablet} {
    margin-bottom: ${desktopVW(20)};
    padding: 0 ${desktopVW(200)};
  }
`;

const H2 = styled(Typography)`
  color: ${color.white};
  position: relative;
  z-index: 1;
  padding: ${mobileVW(15)};
  /* white-space: nowrap; */

  ${minTablet} {
    padding: 0;
  }
`;

const StyledImage = styled(Image)`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  object-fit: cover;
  opacity: 0.75;
`;
