/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';

import {
  desktopVW, minTablet, maxTablet, mobileVW, color, letterSpacing,
} from '../../styles';
import { Typography } from '../../shared/Typography';
import { useAddItemToCart } from '../../hooks/shop/useAddItemToCart';
import { Accordion } from '../../shared/Accordion';

interface componentProps {
  hero: {
    layout: string
    heading: string
    subHeading: string
    description: string
    images: any
    info: string
    product: any
    poster: any
    video: any
    button: any
    accordion: {
      title: string
      body: string
    }
    image: {
      altText: string
      title: string
      localFile : {
        childImageSharp: {
          fluid: {
            src: string
          }
        }
      }
    }
  }
}

export const ProductHero = ({ hero }: componentProps) => {
  const { variants } = hero.product;

  const addItemToCart = useAddItemToCart();
  const [selectedProduct, setSelectedProduct] = useState(variants[0]);
  const [adding, setAdding] = useState(false);
  const [activeClass, setActiveClass] = useState('');

  const { storefrontId, price } = selectedProduct;

  const handleAddToCart = async () => {
    setAdding(true);
    try {
      await addItemToCart(storefrontId, 1)
        .then(() => setAdding(false));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('There was a problem adding that item to your cart.', error);
    }
  };

  return (
    <StyledProductHero>
      <StyledHeading size="is-xl" variants="h1">{ hero.heading }</StyledHeading>
      <StyledGrid>
        <StyledImageColumn>
          {hero.images.map((image : any, index: number) => (
            // @ts-ignore
            <StyledImage key={index} fluid={image.localFile.childImageSharp.fluid} alt={image.altText} />
          ))}
        </StyledImageColumn>
        <StyledColumn>
          <Container>
            <StyledSubtitle>{hero.info}</StyledSubtitle>
            {hero.subHeading && (
            <StyledTypography size="is-sm" variants="h3">
              {hero.subHeading}
            </StyledTypography>
            )}
            <StyledButtonWrapper>
              <StyledContainer onMouseLeave={() => setActiveClass('')} onMouseEnter={() => setActiveClass('active')} className={activeClass}>
                <SelectContainer className="size-selector">
                  {variants.map((size : any, index: number) => (
                    <StyledOptionButtons
                      key={index}
                      type="button"
                      className={size.title === selectedProduct.title ? 'selected' : ''}
                      onClick={() => setActiveClass('')}
                      onMouseEnter={() => {
                        setSelectedProduct(size);
                      }}
                    >
                      {size.title === 'extra large' ? 'xl' : size.title}
                    </StyledOptionButtons>
                  ))}
                </SelectContainer>
                <div
                  style={{
                    position: 'absolute', inset: 0,
                  }}
                  onClickCapture={() => setActiveClass('active')}
                />
                <span>{selectedProduct.title === 'extra large' ? 'xl' : selectedProduct.title}</span>
                <Svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.967 4.541">
                  <path d="M3.983 4.541a.556.556 0 01-.394-.163L.163.952A.56.56 0 01.952.159L3.983 3.19 7.014.159a.558.558 0 01.789.789L4.377 4.374a.556.556 0 01-.394.167z" />
                </Svg>

              </StyledContainer>
              <StyledButton onClick={handleAddToCart}>
                <span>
                  {adding ? 'Toevoegen aan winkelmand' : `In winkelmandje voor €${price}`}
                </span>
              </StyledButton>
            </StyledButtonWrapper>
            <Accordion items={hero.accordion} />
          </Container>
        </StyledColumn>
      </StyledGrid>
    </StyledProductHero>
  );
};

const StyledProductHero = styled.header`
  margin-bottom: ${mobileVW(60)};
  
  ${minTablet} {
    margin-bottom: ${desktopVW(120)};
  }
`;

const SelectContainer = styled.div`
  position: absolute;
  left: 0; top: 50%;
  transform: translateY(-50%);
  background: ${color.white};
  width: ${mobileVW(138)};
  border-radius: ${mobileVW(16)};
  box-shadow: 0 0 ${mobileVW(10)} #00000016;
  padding: ${mobileVW(9)};
  cursor: default;
  z-index: 9;
  display: none;

  ${minTablet} {
    width: ${desktopVW(138)};
    border-radius: ${desktopVW(16)};
    box-shadow: 0 0 ${desktopVW(10)} #00000016;
    padding: ${desktopVW(9)};
  }
`;

const StyledGrid = styled.div`
  /* display: flex;
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  margin: 0 ${desktopVW(60)}; */

  ${minTablet} {
    display: flex;
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto;
    margin: 0 ${desktopVW(60)};
  }
`;

const StyledColumn = styled.div`
  position: relative;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: ${mobileVW(28)};
  font-size: ${mobileVW(17)};
  letter-spacing: ${letterSpacing(30)};
  color: ${color.white};
  background: transparent;
  border: none;
  padding: ${mobileVW(20)} 0;
  padding-right: ${mobileVW(22)};
  padding-left: ${mobileVW(30)};
  position: relative;
  
  ${minTablet} {
    padding: ${desktopVW(20)} 0;
    padding-right: ${desktopVW(22)};
    padding-left: ${desktopVW(30)};
    line-height: ${desktopVW(28)};
    font-size: ${desktopVW(17)};
    
  }

  &.active .size-selector {
    display: block;
  }
`;

const StyledImageColumn = styled.div`
  ${maxTablet} {
    display: grid;
    grid-template-columns: repeat(4, 100vw);
    overflow-x: scroll;
    -webkit-overflow-scrolling: start;
    scroll-snap-type: x mandatory;
    grid-gap: ${mobileVW(15)};
    height: ${mobileVW(300)};
    margin: 0 ${mobileVW(15)};
  }

  ${minTablet} {
    position: sticky;
    width: 100%;
  }
`;

const StyledButtonWrapper = styled.div`
  background: ${color.black};
  width: auto;
  display: inline-flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  border-radius: ${mobileVW(10)};
  align-items: center;
  margin-bottom: ${mobileVW(50)};
  cursor: pointer;
  
  ${minTablet} {
    margin-bottom: ${desktopVW(50)};
    border-radius: ${desktopVW(10)};
  }
`;

const StyledOptionButtons = styled.button`
  width: 100%;
  border: none;
  padding: ${mobileVW(10)} 0;  
  border-radius: ${mobileVW(10)};  
  cursor: pointer;
  background: ${color.greyLight};
  text-transform: capitalize;
  transition: all .3s cubic-bezier(0.46, 0, 0.31, 1);
  
  ${minTablet} {
    padding: ${desktopVW(10)} 0;  
    border-radius: ${desktopVW(10)};  
  }

  &.selected {
    background: ${color.black};
    color: ${color.white};
  }

  & + & {
    margin-top: ${mobileVW(3)};
    
    ${minTablet} {
      margin-top: ${desktopVW(3)};
    }
  }
`;

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  line-height: ${mobileVW(28)};
  font-size: ${mobileVW(17)};
  letter-spacing: ${letterSpacing(30)};
  color: ${color.white};
  background: transparent;
  border: none;
  padding: ${mobileVW(20)} 0;
  padding-left: ${mobileVW(20)};
  justify-content: flex-end;
  position: relative;
  padding-right: ${mobileVW(30)};
  transition: all .3s cubic-bezier(0.46, 0, 0.31, 1);

  ${minTablet} {
    line-height: ${desktopVW(28)};
    font-size: ${desktopVW(17)};
    padding: ${desktopVW(20)} 0;
    padding-left: ${desktopVW(20)};
    padding-right: ${desktopVW(30)};
  }
  
  &:hover {
    background: ${color.greyLight};
    color: ${color.black};
    border-radius: ${mobileVW(10)};
    
    ${minTablet} {
      border-radius: ${desktopVW(10)};
    }
  }
  
  &:before {
    content: '';
    width: ${mobileVW(1)};
    height: ${mobileVW(14)};
    background: ${color.greyDark};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    
    ${minTablet} {
      width: ${desktopVW(1)};
      height: ${desktopVW(14)};  
    }
  }
`;

const StyledImage = styled(Image)`
  /* width: ${mobileVW(836)}; */
  
  ${maxTablet} {
    pointer-events: none;
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }

  ${minTablet} {
    width: ${desktopVW(836)};
  }
  `;

const StyledTypography = styled(Typography)`
  font-size: ${mobileVW(40)};
  margin-bottom: ${mobileVW(30)};

  ${minTablet} {
    font-size: ${desktopVW(56)};
    margin-bottom: ${desktopVW(30)};
  }
`;

const Svg = styled.svg`
  z-index: 2;
  width: ${mobileVW(9.5)};
  margin-left: ${mobileVW(8)};
  
  ${minTablet} {
    margin-left: ${desktopVW(8)};
    width: ${desktopVW(9.5)};
  }
`;

const StyledHeading = styled(Typography)`
  margin-bottom: ${mobileVW(30)};
  margin-top: ${mobileVW(15)};
  padding: 0 ${mobileVW(15)};
  
  ${minTablet} {
    margin-top: 0;
    margin-bottom: ${desktopVW(40)};
    padding: 0 ${desktopVW(60)};
  }
`;

const Container = styled.div`
  /* width: ${desktopVW(420)}; */
  /* top: ${desktopVW(50)}; */
  ${maxTablet} {
    margin: 0 ${mobileVW(15)};
  }

  ${minTablet} {
    position: sticky;
    width: ${desktopVW(420)};
    top: ${desktopVW(50)};
  }
`;

const StyledSubtitle = styled.p`
  color: #5B5E69;
  font-size: ${mobileVW(20)};
  margin-bottom: ${mobileVW(15)};
  margin-top: ${mobileVW(30)};
  
  ${minTablet} {
    margin-top: 0;
    font-size: ${desktopVW(20)};
    margin-bottom: ${desktopVW(30)};  
  }
`;
