import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { color } from '../styles';

const StyledVideo = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
const VideoPlayer = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
  /* &::-webkit-media-controls {
    display: none;
    -webkit-appearance: none;
  } */
`;
const StyledImage = styled(Image)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  background: ${color.black};
  position: absolute !important;
  top: 0;
  left: 0;
  display: ${(props : any) => (props.hidden ? 'none' : 'block')};
`;

export const Video = ({
  mov, mp4, poster, className, controls,
} : { mov? : string, mp4: string, poster: any, className?: any, controls?: any }) => {
  const videoEl = useRef({ readyState: 0, play: () => {} });
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (videoEl.current.readyState >= 3) {
        videoEl.current.play();
        setReady(true);
        clearInterval(intervalId);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <StyledVideo className={className}>
      <VideoPlayer
        autoPlay
        disableRemotePlayback
        loop
        playsInline
        muted
        // @ts-ignore
        ref={videoEl}
        controls={!!controls}
        preload="none"
      >
        <source src={mov} type="video/mov" />
        <source src={mp4} type="video/mp4" />
      </VideoPlayer>
      {poster && (
        <StyledImage
          // @ts-ignore
          hidden={ready}
          fluid={poster}
          withPlaceholder
          alt="video poster"
        />
      )}
    </StyledVideo>
  );
};
